.d-block {
  display: block !important;
}
.d-flex {
  display: flex;
}
.jc-ctr {
  justify-content: center;
}
.jc-space-even {
  justify-content: space-evenly;
}

.algn-itm-ctr {
  align-items: center;
}

.f-wrap {
  flex-wrap: wrap;
}

.txt-left {
  text-align: left;
}

.txt-center {
  text-align: center;
}

.w-200 {
  width: 200%;
}
.w-100 {
  width: 100%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}
.pt-1 {
  padding-top: 10px;
}
.pt-2 {
  padding-top: 20px;
}
.pb-1 {
  padding-bottom: 10px;
}
.pb-2 {
  padding-bottom: 20px;
}
.py-1 {
  padding: 10px 0;
}
.py-2 {
  padding: 20px 0;
}
.pr-1 {
  padding-right: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.ml-1 {
  margin-left: 10px;
}
.m-1 {
  margin: 5px;
}
.cur-pointer {
  cursor: pointer;
}

.m-a {
  margin: auto !important;
}

.error {
  font-size: 12px;
  color: red;
  margin-bottom: 0;
  opacity: 0.7;
  margin: 0;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #000 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #000 !important;
}
